// Here you can add other styles

.embed {
  height: calc(100vh - 320px)
}


.sidebar {
  --cui-sidebar-brand-height: 7.1rem
}

.sidebar-brand {
  cursor: pointer;
}

.sidebar-brand-full {
  height: 60%
}

.sidebar-brand-narrow {
  width: 60%
}

.nav-link {
  cursor: pointer;
}

.page-link {
  cursor: pointer;
}

.form-multi-select, .form-select {
  cursor: pointer;
}

.toaster.top-0 {
  width: 100%;

  .toast {
    margin-left: auto;
    margin-right: auto;
  }
}

.btn {
  --cui-btn-border-radius: 25px!important;
  --cui-btn-padding-x: 20px!important;
}

.pagination {
  --cui-pagination-border-radius: 25px;
}

.form-control {
  border-radius: 25px;
  padding-left: 12px;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding-left: 22px;
}

.form-multi-select {
  --cui-form-multi-select-border-radius: 25px
}

html:not([dir=rtl]) .form-floating > .form-select {
  border-radius: 25px;
  padding-left: 22px
}

.input-group-text {
  border-radius: 25px;
}

html:not([dir=rtl]) .form-floating > label {
  left: 10px!important
}

.card {
  --cui-card-border-radius: 25px
}

.card-header {
  --cui-card-cap-padding-y: 1.4rem;
  --cui-card-inner-border-radius: 25px;
}

.card-footer {
  --cui-card-inner-border-radius: 25px;
}

.card-body {
  // --cui-card-spacer-y: 1.4rem;
  // --cui-card-spacer-x: 1.4rem;
}

.bg-light {
  background: fixed linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) 5%)!important;
}

.dark-theme .bg-light {
  --cui-light-rgb: 92,95,120;
  background: fixed linear-gradient(180deg, #2a2b36 0%, #2a2b36 5%, rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) 5%, rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) 98%, rgba(255,255,255, 0) 98%,), scroll linear-gradient(rgba(255,255,255, 0) 98%, #2a2b36 98%, #2a2b36 100%)!important;
}

.sidebar {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.sidebar-brand {
  border-top-right-radius: 25px;
}

.sidebar-toggler {
  border-bottom-right-radius: 25px;
}

.header.header-sticky {
  box-shadow: 3px 2px 2px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 2px 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 6px 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .20);
}

.dark-theme .pagination {
  --cui-pagination-active-bg: #586590
}

.border.rounded-6 {
  border-radius: 50%;
}

.form-multi-select-search {
  background-color: transparent;
  cursor: pointer;
}

.form-multi-select.form-multi-select-with-cleaner {
  padding-right: 0.4rem!important;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  gap: 1rem;
}

.preview {
  img {
    max-height: 40px;
    margin-right: 10px;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  vertical-align: middle;
}

.text-middle {
  vertical-align: middle;
}

.tab-pane {
  padding: 16px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #c4c9d0;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #ffffff;
}

.pagination {
  margin-bottom: 0!important;
}

@media (min-width: 1360px) {
  .download-report {
    position: absolute;
    bottom: 3px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 160px;
  }
}

@media (max-width: 1360px) {
  .download-report {
    margin-left: auto;
    display: block;
  }
}
